<template>
  <div class="container">
    <div class="upload">
      <a-upload
        name="file"
        :multiple="true"
        :action="uploadUrl"
        :data="{
          token: qiniuData.token
        }"
        @change="handleChange"
      >
        <a-button> <a-icon type="upload" /> Click to Upload </a-button>
      </a-upload>
    </div>
    <p>{{ url }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: '',
      uploadUrl: process.env.VUE_APP_QINIUPHOST,
      qiniuData: {
        host: 'https://qiniu.aliu6.com/',
        token:
          'ROxe1jMiG2kJNEIvC4LbYsYRLv6JqcFyCKsfAtA5:bw1sT-DOKPFyxPa40R4ciG_vZn8=:eyJzY29wZSI6ImFsaXUiLCJkZWFkbGluZSI6MTYyNzY4ODMzNn0='
      }
    }
  },
  methods: {
    handleChange(info) {
      // 头像上传
      if (info.file.status === 'done') {
        this.url = this.qiniuData.host + info.file.response.key
      }
    }
  }
}
</script>

<style scoped>
.upload {
  width: 100px;
  height: 100px;
}
</style>
